import React from "react";
import UserMessageBox from "./UserMessageBox";
import hashing from "./hashing";
import ChangeLabel from "./ChangeLabel";

const Textarea = ({
  input,
  label,
  disabled,
  meta,
  width,
  height,
  requiresUnlock,
  maxLength,
}) => {
  const hashedId = hashing(input.name);
  return (
    <div>
      <div className="label-container flex justify-between pb-2">
        <label htmlFor={hashedId} className="custom-label">
          {label}
        </label>
        {requiresUnlock ? (
          <ChangeLabel inputName={input.name} disabled={disabled} />
        ) : (
          ""
        )}
      </div>
      <div>
        <textarea
          {...input}
          id={hashedId}
          name={input.name}
          style={{ width: width, height: height }}
          disabled={disabled}
          maxLength={maxLength ? maxLength : false}
        />
        <UserMessageBox meta={meta} />
      </div>
    </div>
  );
};

export default Textarea;
