import {
  LOAD_DATA,
  SAVE_FAILED,
  DATA_RECEIVED,
  SAVE_SUCCESSFUL,
  LOAD_CITY_SUCCESSFUL,
  LOAD_STREET_SUCCESSFUL,
  VALIDATE_ADDRESS_SUCCESSFUL,
  VALIDATE_BANK_SUCCESSFUL,
  LOAD_DATA_ERROR,
  MODIFY_FIELD,
  FETCH_STREET_SUGGESTIONS_SUCCESSFUL,
  CLEAR_CITY,
  IBAN_REQUEST_FAILED,
  IBAN_REQUEST_SUCCESS,
  FORMULAR_DATA_LOADING_SUCCESSFUL,
  LOADING_RANKS_SUCCESSFUL,
  LOADING_MILITARY_UNITS_SUCCESSFUL,
  LOADING_EMPLOYMENT_PLACES_SUCCESSFUL,
} from '../actions/editMembership';

const editMembership = (
  state = { loading: true, data: {}, errors: {}, streets: [] },
  action,
) => {
  switch (action.type) {
    case LOAD_DATA:
      return {
        ...state,
        loading: true,
      };
    case DATA_RECEIVED:
      const memberData = action.data.member;
      if (!memberData.lkz || memberData.lkz === '') {
        memberData.lkz = 'DE';
      }
      const initialCity = action.data.member.ort;
      return {
        ...state,
        data: memberData,
        countries: action.data.countries,
        // this is dispatche once, so we can do this here so the value is initial shown
        cities: [{ value: initialCity, label: initialCity }],
        mainStatus: action.data.main_status,
        nobleTitles: action.data.noble_titles,
        academicTitles: action.data.academic_titles,
      };
    case LOAD_DATA_ERROR:
      return {
        ...state,
        errors: {
          member: 'not_found',
        },
        loading: false,
      };
    case SAVE_SUCCESSFUL:
      return {
        ...state,
        successfulSave: true,
      };
    case SAVE_FAILED:
      return {
        ...state,
        successfulSave: false,
        errors: action.data.errors,
      };
    case LOAD_CITY_SUCCESSFUL:
      return {
        ...state,
        cities: [...action.data],
      };
    case CLEAR_CITY:
      return {
        ...state,
        cities: [],
      };
    case LOAD_STREET_SUCCESSFUL:
      return {
        ...state,
        streets: action.data,
      };
    case VALIDATE_ADDRESS_SUCCESSFUL:
      return {
        ...state,
        validateStreetData: true,
      };
    case VALIDATE_BANK_SUCCESSFUL:
      return {
        ...state,
        validateBankData: true,
      };
    case FETCH_STREET_SUGGESTIONS_SUCCESSFUL:
      return {
        ...state,
        streets: action.data.streets,
      };
    case IBAN_REQUEST_FAILED:
      return {
        ...state,
        errors: {
          iban: 'not_valid',
        },
      };
    case IBAN_REQUEST_SUCCESS:
      return {
        ...state,
        errors: {
          iban: '',
        },
      };
    case FORMULAR_DATA_LOADING_SUCCESSFUL:
      return {
        ...state,
        loading: false,
      };
    case LOADING_RANKS_SUCCESSFUL:
      return {
        ...state,
        ranks: action.data,
      };
    case LOADING_MILITARY_UNITS_SUCCESSFUL:
      return {
        ...state,
        militaryUnits: action.data,
      };
    case LOADING_EMPLOYMENT_PLACES_SUCCESSFUL:
      return {
        ...state,
        employmentPlaces: action.data,
      };
    default:
      return state;
  }
};

export default editMembership;
