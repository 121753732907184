import React from 'react';

class Errors extends React.Component {
  render() {
    const errors = this.props.errors;
    if (
      this.props.successfulSave === false &&
      (errors.lkz || errors.plz || errors.strasse || errors.iban)
    ) {
      const streetError = errors.lkz || errors.plz || errors.strasse;
      const bankError = errors.iban;
      return (
        <div className="alert alert-error error-message">
          <strong>
            Bei der Überprüfung des Formulars sind Fehler aufgetreten:{' '}
          </strong>
          <ul>
            {streetError
              ? <li>
                  Ihre Adresse konnte leider nicht validiert werden. Bitte
                  prüfen Sie Ihre Eingaben.
                </li>
              : ''}
            {bankError
              ? <li>
                  Ihre Bankverbindung konnte leider nicht validiert werden.
                  Bitte prüfen Sie Ihre Eingaben.
                </li>
              : ''}
          </ul>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default Errors;
