import React from "react";
import AsyncSelect from "react-select/async";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import lowerCase from "lodash/lowerCase";
import hashing from "./hashing";
import UserMessageBox from "./UserMessageBox";
import ChangeLabel from "./ChangeLabel";
import { fetchApi } from "helpers/reactQueryApi";
import styles from "components/appCreator/properties/edit/editFields.module.css";

const selector = formValueSelector("membershipForm");

class DisabledStreetField extends React.Component {
  render() {
    return (
      <div>
        <div className="label-container flex justify-between pb-2">
          <label htmlFor={this.props.hashedId} className="custom-label">
            {this.props.label}
          </label>
          <ChangeLabel inputName={this.props.input.name} disabled={true} />
        </div>
        <div>
          <input
            disabled={true}
            id={this.props.hashedId}
            name={this.props.names[0]}
            type="text"
            value={this.props.input.value}
            className={this.props.className}
          />
          <UserMessageBox meta={this.props.meta} />
        </div>
      </div>
    );
  }
}

async function loadOptions({ value, plz }) {
  if (value.length < 3) {
    return [];
  }

  const result = await fetchApi("/custom/dbwv/address/street_suggestions", {
    method: "POST",
    body: { postcode: plz, thoroughfare: lowerCase(value) },
  });
  return result.streets;
}

function StreetInputField({ plz, input, className, names, hashedId, lkz }) {
  if (lkz === "DE") {
    return (
      <AsyncSelect
        classNamePrefix={"Select"}
        value={input.value}
        className={styles.Selection}
        placeholder={"Straße"}
        inputId={hashedId.toString()}
        noOptionsMessage={({ inputValue }) =>
          inputValue.length < 3
            ? `Bitte ${3 - inputValue.length} Buchstaben mehr eingeben`
            : "Es wurden keine Ergebnisse zu ihrer Suche gefunden."
        }
        loadingMessage={() => "Daten werden geladen, bitte warten..."}
        loadOptions={(value) => loadOptions({ value, plz })}
        onChange={input.onChange}
      />
    );
  } else {
    return (
      <div>
        <input
          {...input}
          type="text"
          name={names[0]}
          id={hashedId}
          placeholder="Straße"
          className={"street-field"}
        />
      </div>
    );
  }
}

StreetInputField = connect((state) => {
  return {
    lkz: selector(state, "lkz"),
    plz: selector(state, "plz"),
  };
})(StreetInputField);

class ModifyableStreetField extends React.Component {
  render() {
    return (
      <div>
        <div className="label-container flex justify-between pb-2">
          <label htmlFor={this.props.hashedId} className="custom-label">
            {this.props.label}
          </label>
          <ChangeLabel inputName={this.props.input.name} disabled={false} />
        </div>
        <div className="search-field-container flex flex-col gap-4">
          <div className="search-field">
            <StreetInputField
              input={this.props.input}
              names={this.props.names}
              hashedId={this.props.hashedId}
              streets={this.props.streets}
              className={this.props.className}
            />
          </div>
          <input
            className={"numberField " + this.props.className}
            name={this.props.names[1]}
            type="text"
            placeholder="Hausnummer"
          />
          <UserMessageBox meta={this.props.meta} />
        </div>
      </div>
    );
  }
}

ModifyableStreetField = connect((state) => {
  return {
    streets: state.editMembership.streets,
  };
})(ModifyableStreetField);

class StreetField extends React.Component {
  render() {
    const hashedId = hashing(this.props.input.name);
    const props = {
      hashedId: hashedId,
      meta: this.props.meta,
      input: this.props.input,
      names: this.props.names,
      label: this.props.label,
      className: this.props.className,
    };
    if (
      (this.props.initialValue === this.props.input.value &&
        this.props.initialValue !== "") ||
      (this.props.lkz === "DE" &&
        (this.props.plz === "" || this.props.ort === ""))
    ) {
      return <DisabledStreetField {...props} />;
    } else {
      return <ModifyableStreetField {...props} />;
    }
  }
}

StreetField = connect((state) => {
  return {
    lkz: selector(state, "lkz"),
    plz: selector(state, "plz"),
    ort: selector(state, "ort"),
  };
})(StreetField);

export default StreetField;
