const dbwvNamespace = 'CUSTOM_DBWV_MITGLIEDSCHAFT_';

export const LOAD_DATA = dbwvNamespace + 'LOAD_DATA';
export const SAVE_SUCCESSFUL = dbwvNamespace + 'SAVE_SUCESSFUL';
export const DATA_RECEIVED = dbwvNamespace + 'DATA_RECEIVED';
export const LOAD_COUNTRIES_SUCCESSFUL =
  dbwvNamespace + 'LOAD_COUNTRIES_SUCCESSFUL';
export const LOAD_CITY_SUCCESSFUL = dbwvNamespace + 'LOAD_CITY_SUCCESSFUL';
export const LOAD_STREET_SUCCESSFUL = dbwvNamespace + 'LOAD_STREET_SUCCESSFUL';
export const VALIDATE_ADDRESS_SUCCESSFUL =
  dbwvNamespace + 'VALIDATE_ADDRESS_SUCCESSFUL';
export const VALIDATE_BANK_SUCCESSFUL =
  dbwvNamespace + 'VALIDATE_BANK_SUCCESSFUL';
export const LOAD_DATA_ERROR = dbwvNamespace + 'LOAD_DATA_ERROR';
export const FETCH_STREET_SUGGESTIONS_SUCCESSFUL =
  dbwvNamespace + 'FETCH_STREET_SUGGESTIONS_SUCCESSFUL';
export const SAVE_FAILED = dbwvNamespace + 'SAVE_FAILED';
export const CLEAR_CITY = dbwvNamespace + 'CLEAR_CITY';
export const IBAN_REQUEST_FAILED = dbwvNamespace + 'IBAN_REQUEST_FAILED';
export const IBAN_REQUEST_SUCCESS = dbwvNamespace + 'IBAN_REQUEST_SUCCESS';
export const FORMULAR_DATA_LOADING_SUCCESSFUL =
  dbwvNamespace + 'FORMULAR_DATA_LOADING_SUCCESSFUL';
export const LOADING_RANKS_SUCCESSFUL =
  dbwvNamespace + 'LOADING_RANKS_SUCCESSFUL';
export const LOADING_MILITARY_UNITS_SUCCESSFUL =
  dbwvNamespace + 'LOADING_MILITARY_UNITS_SUCCESSFUL';
export const LOADING_EMPLOYMENT_PLACES_SUCCESSFUL =
  dbwvNamespace + 'LOADING_EMPLOYMENT_PLACES_SUCCESSFUL';

export const fetchStreetSuggestionsSuccessful = data => ({
  type: FETCH_STREET_SUGGESTIONS_SUCCESSFUL,
  data: data,
});

export const ibanRequestFailed = () => ({
  type: IBAN_REQUEST_FAILED,
});

export const ibanRequestSuccess = () => ({
  type: IBAN_REQUEST_SUCCESS,
});

export const saveFailed = data => ({
  type: SAVE_FAILED,
  data: data,
});

export const loadCountriesSuccessful = data => ({
  type: LOAD_COUNTRIES_SUCCESSFUL,
  data: data,
});

export const loadCityData = data => ({
  type: LOAD_CITY_SUCCESSFUL,
  data: data,
});

export const clearCity = data => ({
  type: CLEAR_CITY,
});

export const loadStreetData = data => ({
  type: LOAD_STREET_SUCCESSFUL,
  data: data,
});

export const validateAddressData = () => ({
  type: VALIDATE_ADDRESS_SUCCESSFUL,
});

export const validateBankSuccessful = data => ({
  type: VALIDATE_BANK_SUCCESSFUL,
  data: data,
});

export const loadData = () => ({
  type: LOAD_DATA,
});

export const notValidMember = () => ({
  type: LOAD_DATA_ERROR,
});

export const dataReceived = data => ({
  type: DATA_RECEIVED,
  data: data,
});

export const saveSuccessful = data => ({
  type: SAVE_SUCCESSFUL,
  data: data,
});

export const formularDataLoadingSuccessful = () => ({
  type: FORMULAR_DATA_LOADING_SUCCESSFUL,
});

export const loadingRanksSuccessful = data => ({
  type: LOADING_RANKS_SUCCESSFUL,
  data: data,
});

export const loadingMilitaryUnitsSuccessful = data => ({
  type: LOADING_MILITARY_UNITS_SUCCESSFUL,
  data: data,
});

export const loadingEmploymentPlacesSuccessful = data => ({
  type: LOADING_EMPLOYMENT_PLACES_SUCCESSFUL,
  data: data,
});
