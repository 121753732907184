import React from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
import { ibanRequestSuccess } from "../actions/editMembership";

const changeTable = {
  lkz: ["lkz", "plz", "ort", "strasse"],
  plz: ["plz", "ort", "strasse"],
  iban: ["iban", "bic", "bankVerbi"],
};

class ChangeLabel extends React.Component {
  handleClick = (e, name, disabled) => {
    e.preventDefault();

    if (disabled) {
      if (changeTable[name]) {
        changeTable[name].map((fieldName) => {
          this.props.dispatch(change("membershipForm", fieldName, ""));
        });
      } else {
        this.props.dispatch(change("membershipForm", name, ""));
      }
    } else {
      if (changeTable[name]) {
        changeTable[name].map((fieldName) => {
          this.props.dispatch(
            change(
              "membershipForm",
              fieldName,
              this.props.initialValues[fieldName]
            )
          );
          if (fieldName === "iban") {
            // Remove error message in ui, after iban changed to initialValue
            this.props.dispatch(ibanRequestSuccess());
          }
        });
      } else if (name !== "ort") {
        this.props.dispatch(
          change("membershipForm", name, this.props.initialValues[name])
        );
      }
    }
  };

  renderLink = (label, disabled) => (
    <div className="modify-input">
      <a
        href="#"
        onClick={(e) => this.handleClick(e, this.props.inputName, disabled)}
      >
        {label}
      </a>
    </div>
  );

  render() {
    if (this.props.disabled) {
      return this.renderLink("Ändern", this.props.disabled);
    } else {
      return this.renderLink("Abbrechen", this.props.disabled);
    }
  }
}

ChangeLabel = connect((state) => {
  return {
    initialValues: state.editMembership.data,
  };
})(ChangeLabel);

export default ChangeLabel;
