import React from "react";
import UserMessageBox from "./UserMessageBox";
import hashing from "./hashing";
import { Field } from "redux-form";

const Checkbox = ({ input, id, label, type, disabled, meta }) => {
  const hashedId = hashing(input.name);
  return (
    <div>
      <label htmlFor={hashedId} className="checkbox inline">
        <Field
          {...input}
          id={hashedId}
          component="input"
          type="checkbox"
          value={true}
          name={input.name}
          style={{ marginRight: 10 }}
          disabled={disabled}
        />
        {label}
      </label>
      <UserMessageBox meta={meta} />
    </div>
  );
};

export default Checkbox;
