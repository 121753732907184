import React from 'react';

const UserMessageBox = ({ meta }) => {
  if (meta.touched) {
    if (meta.error) {
      return (
        <p className="alert alert-error message">
          {meta.error}
        </p>
      );
    }
    if (meta.warning) {
      return (
        <p className="alert alert-warning message">
          {meta.warning}
        </p>
      );
    }
  }
  return null;
};

export default UserMessageBox;
