import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { change } from "redux-form";
import UserMessageBox from "./UserMessageBox";
import styles from "components/appCreator/properties/edit/editFields.module.css";

class SelectWithSearchField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      q: props.input.value || "",
    };
  }

  onChange = (newValue) => {
    this.setState({ q: newValue.value });
    this.props.onChange(newValue.value);
  };

  render() {
    return (
      <div>
        <div className="label-container flex justify-between pb-2">
          <label htmlFor={this.props.hashedId} className="custom-label">
            {this.props.label}
          </label>
        </div>
        <div className="search-field-container">
          <div className="search-field">
            <Select
              value={{ value: this.state.q, label: this.state.q }}
              classNamePrefix={"Select"}
              placeholder={this.props.label}
              className={styles.Selection}
              noOptionsMessage={() =>
                "Es wurden keine Ergebnisse zu ihrer Suche gefunden."
              }
              inputId={this.props.hashedId?.toString()}
              options={this.props.options}
              onChange={this.onChange}
              isDisabled={this.props.disabled}
            />
          </div>
          <UserMessageBox meta={this.props.meta} />
        </div>
      </div>
    );
  }
}

SelectWithSearchField = connect(null, (dispatch, { input }) => ({
  onChange: (value) => {
    dispatch(change("membershipForm", input.name, value));

    if (input.onChange) {
      input.onChange(value);
    }
  },
}))(SelectWithSearchField);

export default SelectWithSearchField;
