import React from "react";
import { reduxForm, Field, formValueSelector, change } from "redux-form";
import { connect } from "react-redux";
import { debounce, isEmpty, isObject } from "lodash";
import {
  saveSuccessful,
  saveFailed,
  loadCityData,
  clearCity,
  ibanRequestFailed,
  ibanRequestSuccess,
  loadingMilitaryUnitsSuccessful,
} from "./actions/editMembership";
import Checkbox from "./customFields/Checkbox";
import Textarea from "./customFields/Textarea";

import SelectField from "./customFields/SelectField";
import DefaultInput from "./customFields/DefaultInput";
import StreetField from "./customFields/StreetField";
import Notification from "./customFields/Notification";
import SelectWithSearchField from "./customFields/SelectWithSearchField";
import Errors from "./customFields/Errors";
import { DatePickerAdapter } from "./customFields/DateField";

const required = (value) =>
  value ? undefined : "Dieses Feld darf nicht leer sein.";

class MembershipForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { militaryUnitsLoading: false };
  }

  componentDidMount() {
    // initial change, because other fields, which are not present in data, are not present in the submit if untouched
    this.props.dispatch(change("membershipForm", "empfBest", false));
    this.props.dispatch(change("membershipForm", "anfAusw", false));

    if (this.props.initialValues.standort) {
      console.debug(
        "Standort present:",
        this.props.initialValues.standort,
        "Loading Military Units..."
      );
      this.standortChange(this.props.initialValues.standort);
    }
  }

  handleSubmit = (data) =>
    new Promise((resolve, reject) => {
      // Redux-Form does not include the streetnumber field in data, because it is not present in the initial loading.
      const strasse = data["strasse"];
      const houseNumberField = $(".numberField")[0];
      if (houseNumberField && !isEmpty(houseNumberField.value)) {
        data["strasse"] =
          (isObject(data["strasse"])
            ? data["strasse"].label
            : data["strasse"]) +
          " " +
          houseNumberField.value;
      }

      // if bemerkung is empty. Cannot set initial value, state does not save an empty string.
      if (isEmpty(data["bemerkung"])) {
        data["bemerkung"] = "";
      }

      ["gebDat", "dzEnde"].forEach((fieldName) => {
        if (isEmpty(data[fieldName])) data[fieldName] = "0000-00-00";
      });

      $.ajax(`${Tixxt.currentNetwork.get("url")}/custom/dbwv/mitgliedschaft`, {
        method: "PUT",
        data: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        error: (response) => {
          const json = response.responseJSON;
          data["strasse"] = strasse;
          this.props.dispatch(saveFailed(json));
          window.scrollTo(0, 0);
          reject(json);
        },
        success: (json) => {
          this.props.dispatch(saveSuccessful(json));
          resolve(json);
        },
      });
    });

  plzChange = (event, newValue, oldValue) => {
    if (oldValue !== newValue) {
      if (newValue.length <= 5) {
        this.props.dispatch(change("membershipForm", "plz", newValue));
        if (this.props.lkz === "DE") {
          this.props.dispatch(clearCity());
          this.props.dispatch(change("membershipForm", "ort", ""));
        }
      }
      if (newValue.length === 5 && this.props.lkz === "DE") {
        $.ajax(
          Tixxt.currentNetwork.get("url") + "/custom/dbwv/address/postal_code",
          {
            method: "POST",
            data: JSON.stringify({ postal_code: newValue }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        ).then((json) => {
          this.props.dispatch(loadCityData(json["postal_code"]));
          if (json["postal_code"][0] !== undefined) {
            this.props.dispatch(
              change("membershipForm", "ort", json["postal_code"][0]["value"])
            );
          }
        });
      }
    }
  };

  ibanRequest = debounce((value) => {
    $.ajax(Tixxt.currentNetwork.get("url") + "/custom/dbwv/bank", {
      method: "POST",
      data: JSON.stringify({ iban: value }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((json) => {
      if (json["errors"] && json["errors"]["iban"] === "not_valid") {
        this.props.dispatch(ibanRequestFailed());
      } else {
        this.props.dispatch(change("membershipForm", "bic", json["bic"]));
        this.props.dispatch(
          change("membershipForm", "bankVerbi", json["bankVerbi"])
        );
        this.props.dispatch(ibanRequestSuccess());
      }
    });
  }, 3000);

  militaryUnitsRequest = (value) => {
    this.setState({ militaryUnitsLoading: true });
    $.ajax(Tixxt.currentNetwork.get("url") + "/custom/dbwv/military_units", {
      method: "GET",
      data: { standort: value },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .done((json) => {
        this.setState({ militaryUnitsLoading: false });
        console.info("MilitaryUnit REQUEST SUCCESS");
        this.props.dispatch(loadingMilitaryUnitsSuccessful(json));
      })
      .fail((request, textStatus, errorThrown) => {
        this.setState({ militaryUnitsLoading: false });
        console.error(
          "Error requesting MilitaryUnits:",
          request.status,
          errorThrown,
          request.responseText
        );
      });
  };

  ibanChange = (event, newValue) => {
    if (newValue.length <= 34) {
      this.props.dispatch(change("membershipForm", "iban", newValue));
      this.props.dispatch(change("membershipForm", "bic", ""));
      this.props.dispatch(change("membershipForm", "bankVerbi", ""));
    }
    if (newValue.length >= 15 && newValue.length <= 34) {
      this.ibanRequest(newValue);
    }
  };

  standortChange = (newValue) => {
    this.militaryUnitsRequest(newValue);
  };

  render() {
    const { handleSubmit, pristine, reset, submitting, errors } = this.props;
    let plzProps;
    let ortField;
    let strasseProps;
    if (this.props.lkz === "DE") {
      strasseProps = { validate: [required], label: "Straße und Hausnummer*" };
      let ortOptions;
      if (this.props.lkz !== "" && this.props.plz !== "") {
        ortOptions = this.props.cities;
      } else {
        ortOptions = [];
      }
      ortField = (
        <Field
          name="ort"
          component={SelectField}
          options={ortOptions}
          initialValue={this.props.initialValues.ort}
          validate={[required]}
          label="Wohnort*"
          requiresUnlock
        />
      );
      plzProps = { validate: [required], label: "PLZ*" };
    } else {
      strasseProps = { label: "Straße und Hausnummer" };
      ortField = (
        <Field
          name="ort"
          type="text"
          component={DefaultInput}
          label="Wohnort"
          initialValue={this.props.initialValues.ort}
          requiresUnlock
        />
      );
      plzProps = { label: "PLZ" };
    }

    return (
      <form
        onSubmit={handleSubmit(this.handleSubmit)}
        id="mitgliedschaft-formular"
        className="span12 flex flex-col gap-2"
      >
        <Errors errors={errors} successfulSave={this.props.successfulSave} />
        <div className="control-group flex -my-2 gap-2">
          <span>Mitgliedsnummer:</span>
          <strong>{this.props.mitgliedNr}</strong>
        </div>
        <h3>Persönliche Daten</h3>
        <div className="control-group grid grid-cols-2 gap-4">
          <Field
            component={DatePickerAdapter}
            className="form-input"
            name="gebDat"
            label="Geburtsdatum*"
            validate={[required]}
          />
        </div>
        <div className="control-group">
          <Field
            name="anrede"
            component={SelectField}
            label="Anrede"
            options={[
              { value: "Herr", label: "Herr" },
              { value: "Frau", label: "Frau" },
            ]}
          />
        </div>
        <div className="control-group grid grid-cols-2 gap-4">
          <Field
            name="name"
            component={DefaultInput}
            type="text"
            validate={[required]}
            label="Name*"
          />
          <Field
            name="vorname"
            component={DefaultInput}
            type="text"
            validate={[required]}
            label="Vorname*"
          />
        </div>
        <div className="control-group grid grid-cols-2 gap-4">
          <Field
            name="titel"
            component={SelectField}
            label="Titel"
            options={this.props.nobleTitles}
            initialValue={this.props.initialValues.titel}
            modifyAble
          />
          <Field
            name="akadTitel"
            component={SelectField}
            label="akad. Titel"
            options={this.props.academicTitles}
            initialValue={this.props.initialValues.akadTitel}
            modifyAble
          />
        </div>
        <div className="flex flex-col gap-4">
          <div className="control-group grid grid-cols-2  gap-4">
            <Field
              name="lkz"
              component={SelectField}
              label="Land"
              options={this.props.countries}
              initialValue={this.props.initialValues.lkz}
              requiresUnlock
            />
          </div>
          <div className="control-group grid grid-cols-2 gap-4">
            <Field
              name="plz"
              component={DefaultInput}
              type="text"
              maxLength="5"
              onChange={this.plzChange}
              initialValue={this.props.initialValues.plz}
              requiresUnlock
              {...plzProps}
              className={errors.plz === "not_valid" ? "error" : ""}
            />
            {ortField}
          </div>
          <div className="control-group ">
            <Field
              name="strasse"
              label="Strasse und Hausnummer"
              names={["strasse", "hausnummer"]}
              component={StreetField}
              initialValue={this.props.initialValues.strasse}
              requiresUnlock
              {...strasseProps}
              className={errors.strasse === "not_valid" ? "error" : ""}
            />
          </div>
          {!this.props.strasse &&
          (errors.lkz || errors.plz || errors.strasse) ? (
            <div className="alert alert-error error-message">
              Ihre Adresse konnte leider nicht validiert werden. Bitte prüfen
              Sie Ihre Eingaben.
            </div>
          ) : (
            ""
          )}
          <div className="control-group">
            <Field
              name="adrzusatz"
              component={DefaultInput}
              type="text"
              label="Adresszusatz"
            />
          </div>
        </div>
        <div className="control-group grid grid-cols-2 gap-4">
          <Field
            name="pEmail"
            component={DefaultInput}
            type="email"
            label="E-Mail-Adresse"
          />
          <Field
            name="pTelefon"
            component={DefaultInput}
            type="text"
            label="Telefon"
          />
        </div>
        <div className="control-group">
          <Field
            name="pMobil"
            component={DefaultInput}
            type="text"
            label="Mobil"
          />
        </div>
        <h3>Dienstliche Daten</h3>
        <div className="control-group grid grid-cols-2 gap-4">
          <Field
            name="standort"
            component={SelectWithSearchField}
            label="Dienstort"
            onChange={this.standortChange}
            options={this.props.employmentPlaces}
            initialValue={this.props.initialValues.standort}
          />
          <Field
            name="einheit"
            component={SelectWithSearchField}
            label="Einheit"
            disabled={this.state.militaryUnitsLoading}
            options={this.props.militaryUnits}
            initialValue={this.props.initialValues.einheit}
          />
        </div>
        <div className="control-group grid grid-cols-2 gap-4">
          <Field
            name="hauptstatus"
            component={SelectWithSearchField}
            label="Hauptstatus"
            options={this.props.mainStatus}
            initialValue={this.props.initialValues.mainStatus}
          />
          <Field
            name="dienstgrad"
            component={SelectWithSearchField}
            label="Dienstgrad / Amtbezeichnung"
            options={this.props.ranks}
            initialValue={this.props.initialValues.dienstgrad}
            modifyAble
          />
        </div>
        <div className="control-group grid grid-cols-2 gap-4">
          <div>
            <Field
              name="dTelefon"
              component={DefaultInput}
              type="text"
              label="Telefon Dienst"
            />
            <span className="text-sm text-muted">
              Nicht BwKz, sondern Postnummer mit +49 …
            </span>
          </div>
          <Field
            name="dEmail"
            component={DefaultInput}
            type="email"
            label="E-Mail-Adresse Dienst"
          />
        </div>
        <div className="control-group grid grid-cols-2 gap-4">
          <Field
            component={DatePickerAdapter}
            className="form-input"
            name="dzEnde"
            label="Dienstzeitende"
          />
        </div>
        <h3>SEPA-Lastschriftmandat</h3>
        <div className="control-group">
          <Notification>
            <p>Für wiederkehrende Zahlungen, Abbuchung erfolgt monatlich.</p>
            <p>
              Ich ermächtige den DBwV, Zahlungen von meinem Konto mittels
              Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut
              an, die vom DBwV auf mein Konto gezogenen Lastschriften
              einzulösen.
            </p>
            <p>
              Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem
              Belastungsdatum, die Erstattung des belasteten Betrages verlangen.
              Es gelten dabei die mit meinem Kreditinstitut vereinbarten
              Bedingungen.
            </p>
          </Notification>
        </div>
        <br />
        <div className="control-group">
          <Field
            name="iban"
            component={DefaultInput}
            type="text"
            label="IBAN"
            onChange={this.ibanChange}
            className={errors.iban === "not_valid" ? "error" : ""}
            initialValue={this.props.initialValues.iban}
            requiresUnlock
          />
        </div>
        {errors.iban === "not_valid" ? (
          <div className="alert alert-error error-message">
            Ihre Bankverbindung konnte leider nicht validiert werden. Bitte
            prüfen Sie Ihre Eingaben.
          </div>
        ) : (
          ""
        )}
        <div className="control-group grid grid-cols-2 gap-4">
          <Field
            name="bic"
            component={DefaultInput}
            type="text"
            label="BIC"
            disabled={true}
          />
          <Field
            name="bankVerbi"
            component={DefaultInput}
            type="text"
            label="Name Geldinstitut"
            disabled={true}
          />
        </div>
        <div className="control-group">
          <Field
            name="ktoInhaber"
            component={DefaultInput}
            type="text"
            label="Konto-Inhaber"
            initialValue={this.props.initialValues.ktoInhaber}
            requiresUnlock
          />
        </div>
        <div className="control-group">
          <Notification>
            Gläubiger-Identifikationsnummer:
            <p>DBwV e.V. DE49DBw00000057384</p>
            <p>
              Name und Anschrift Kontoinhaber gem. Angaben in diesem Formular.
            </p>
            <p>
              Die für Sie gültige Mandatsreferenz wird nach Erstellung gesondert
              bekannt gegeben.
            </p>
          </Notification>
        </div>
        <br />
        <div className="control-group">
          <Field
            name="bemerkung"
            component={Textarea}
            height={200}
            // Issue: https://gitlab.sys.mixxt.net/tixxt/foundation/-/issues/1139.
            // When you adjust this, please make sure to adjust the .truncate call in MembershipsController#update, too!
            maxLength={250}
            label="Bemerkungen (max. 250 Zeichen)"
          />
        </div>
        <div className="control-group">
          <Field
            name="empfBest"
            component={Checkbox}
            label="Empfangsbestätigung gewünscht?"
          />
        </div>
        <div className="control-group">
          <Field
            name="anfAusw"
            component={Checkbox}
            label="Anforderung Mitgliedsausweis"
          />
        </div>
        <div className="control-group">
          <Field
            name="MagazinOnline"
            component={Checkbox}
            label={
              <span>
                Ich möchte das Verbandsmagazin <u>nur</u> online lesen
              </span>
            }
          />
        </div>
        <div className="control-group">
          <Field
            name="Newsletter_BdW"
            component={Checkbox}
            label={"Ich möchte den Newsletter des Bildungswerkes abonnieren"}
          />
        </div>
        <div className="control-group">
          <Notification>
            <p>Sie haben noch weitere Kontaktdaten bzw. Bankverbindungen?</p>
            <p>
              Wenden Sie sich hierzu freundlicherweise an Ihr Service-Center.
            </p>
            <p>
              Telefon: 0 30-259 260-28 88 oder Email:{" "}
              <a href="mailto:service@dbwv.de">service@dbwv.de</a>
            </p>
          </Notification>
        </div>
        <div className="actions flex gap-2 justify-end my-4 pt-2">
          <button
            type="button"
            className="btn btn-light"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Abbrechen
          </button>
          <button
            type="submit"
            className="btn btn-primary submit-button"
            disabled={submitting}
          >
            Änderungsmeldung absenden
          </button>
        </div>
      </form>
    );
  }
}

MembershipForm = reduxForm({
  form: "membershipForm",
  enableReinitialize: true,
})(MembershipForm);

const selector = formValueSelector("membershipForm");
MembershipForm = connect((state) => ({
  lkz: selector(state, "lkz"),
  gebDat: selector(state, "gebDat"),
  dzEnde: selector(state, "dzEnde"),
  plz: selector(state, "plz"),
  bic: selector(state, "bic"),
  iban: selector(state, "iban"),
  ktoInhaber: selector(state, "ktoInhaber"),
  mitgliedNr: selector(state, "mitgliedNr"),
  initialValues: state.editMembership.data,
  countries: state.editMembership.countries,
  cities: state.editMembership.cities,
  errors: state.editMembership.errors,
  successfulSave: state.editMembership.successfulSave,
  mainStatus: state.editMembership.mainStatus,
  nobleTitles: state.editMembership.nobleTitles,
  academicTitles: state.editMembership.academicTitles,
  ranks: state.editMembership.ranks,
  militaryUnits: state.editMembership.militaryUnits,
  employmentPlaces: state.editMembership.employmentPlaces,
}))(MembershipForm);

export default MembershipForm;
