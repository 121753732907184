// See https://github.com/darkskyapp/string-hash/blob/master/index.js
const hashing = str => {
  let hash = 0;
  let i = str.length;
  while (i >= 0) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }
  if (hash < 0) {
    hash = hash * -1;
  }
  return hash >>> 0;
};

export default hashing;
