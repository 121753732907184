import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import moment from "moment";
import { isEmpty } from "lodash";
import Datepicker from "helpers/Datepicker";
import "../../../../../../node_modules/vanillajs-datepicker/dist/css/datepicker-bs5";
import FieldError from "components/appCreator/items/form/FieldError";

type PropTypes = {
  input: {
    name: string;
    value: string;
    onChange: (value: string | null) => void;
  };
  label: string;
  meta: {
    error?: string;
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {}

export function DatePickerAdapter({ input, meta, label }: PropTypes) {
  const elemRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const datepicker = new Datepicker(elemRef.current);
    elemRef.current?.addEventListener(
      "changeDate",
      function (e: CustomEventInit) {
        input.onChange(
          e.detail.date
            ? moment(
                e.detail.date,
                I18n.t("js.plugins.Locale.Formats.valid_date")
              ).format("YYYY-MM-DD")
            : null
        );
      }
    );

    return () => {
      datepicker.destroy();
    };
  });

  return (
    <>
      <div className={` flex flex-col input-group date  `}>
        <div className="label-container flex  pb-2">
          <label className="custom-label">{label}</label>
        </div>
        <div className="flex input-group">
          <input
            type="text"
            className={"form-control"}
            value={
              !isEmpty(input.value) && input.value != "0000-00-00"
                ? moment(input.value).format(
                    I18n.t("js.plugins.Locale.Formats.valid_date")
                  )
                : ""
            }
            onChange={noop}
            ref={elemRef}
          />
          <button
            className="input-group-text"
            onClick={(e) => {
              e.preventDefault();
              elemRef.current?.focus();
            }}
          >
            <FontAwesomeIcon icon={solid("calendar")} />
          </button>
        </div>
      </div>
      {meta?.error && <FieldError error={meta.error} />}
    </>
  );
}

function DateField({ required, id, disabled, name }) {
  return (
    <Field
      component={DatePickerAdapter}
      className={`property-${name} form-input`}
      name={name}
      required={required}
      id={id}
      disabled={disabled}
    />
  );
}

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
};

export default DateField;
