import React from "react";
import UserMessageBox from "./UserMessageBox";
import hashing from "./hashing";
import ChangeLabel from "./ChangeLabel";

const DefaultInput = ({
  input,
  label,
  type,
  disabled,
  meta,
  className,
  initialValue,
  requiresUnlock,
  maxLength,
}) => {
  const hashedId = hashing(input.name);
  const disabledInput =
    disabled || (initialValue !== "" && input.value === initialValue);
  return (
    <div className="items-center">
      <div className="label-container flex justify-between pb-2">
        <label htmlFor={hashedId} className="custom-label">
          {label}
        </label>
        {requiresUnlock ? (
          <ChangeLabel inputName={input.name} disabled={disabledInput} />
        ) : (
          ""
        )}
      </div>
      <div className="flex">
        <input
          {...input}
          id={hashedId}
          name={input.name}
          type={type}
          maxLength={maxLength}
          disabled={
            disabledInput ||
            (requiresUnlock &&
              input.value === initialValue &&
              initialValue !== "")
          }
          onFocus={input.onFocus}
          onChange={input.onChange}
          className={className + "grow"}
        />
        <UserMessageBox meta={meta} />
      </div>
    </div>
  );
};

export default DefaultInput;
