import React from "react";
import ReactDOM from "react-dom";
import { Provider, connect } from "react-redux";
import store from "store";
import MembershipForm from "./MembershipForm";
import editMembership from "./reducers/editMembership";
import {
  dataReceived,
  notValidMember,
  formularDataLoadingSuccessful,
  loadingRanksSuccessful,
  loadingEmploymentPlacesSuccessful,
  loadingMilitaryUnitsSuccessful,
} from "./actions/editMembership";

// Add reducers to global store
store.addReducers({
  editMembership: editMembership,
});

class SuccessfulSave extends React.Component {
  render() {
    return (
      <div className="mitgliedschaft-message">
        <div className="alert alert-info message">
          Ihre Änderungsmeldung ist erfolgreich an den DBwV übermittelt worden.
        </div>
      </div>
    );
  }
}

class LoadingScreen extends React.Component {
  render() {
    return (
      <div className="mitgliedschaft-message">
        <div className="alert alert-info changing-screen message">
          Benötigte Daten werden geladen, bitte warten.
        </div>
        <div className="mitgliedschaft-loading">
          <i className="fa fa-spinner fa-spin" /> Lade: {this.props.loads}
        </div>
      </div>
    );
  }
}

class IsChangingScreen extends React.Component {
  render() {
    return (
      <div className="mitgliedschaft-message">
        <div className="alert alert-info changing-screen message">
          Für Ihren Datensatz liegt bereits eine Änderungsmeldung vor. Sobald
          diese bearbeitet wurde, können Sie weitere Änderungen vornehmen.
        </div>
      </div>
    );
  }
}

class NotValidMember extends React.Component {
  render() {
    return (
      <div className="mitgliedschaft-message">
        <div className="alert alert-error message">
          Leider ist bei der Abfrage Ihrer Daten ein Fehler aufgetreten und eine
          Änderungsmeldung daher nicht möglich. Bei Fragen dazu wenden Sie sich
          bitte an&nbsp;
          <a href="mailto:service@dbwv.de">service@dbwv.de</a>
        </div>
        <a href={Tixxt.currentNetwork.get("url")} className="btn btn-primary">
          Zurück
        </a>
      </div>
    );
  }
}

class EditMembership extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      request: {
        name: "",
      },
    };

    this.networkUrl = Tixxt.currentNetwork.get("url");
    this.counter = 0;
    this.requestData = [
      {
        url: this.networkUrl + "/custom/dbwv/mitgliedschaft",
        name: "Stammdaten",
        successfulLoading: (json) => this.props.loadingMemberSuccessful(json),
      },
      {
        url: this.networkUrl + "/custom/dbwv/ranks",
        name: "Dienstgrade",
        successfulLoading: (json) => this.props.loadingRanksSuccessful(json),
      },
      {
        url: this.networkUrl + "/custom/dbwv/employment_places",
        name: "Dienstorte",
        successfulLoading: (json) =>
          this.props.loadingEmploymentPlacesSuccessful(json),
      },
    ];
  }

  loadFormData = () => {
    const data = this.requestData[this.counter];
    this.setState({
      request: {
        name: data.name,
      },
    });
    $.ajax(data.url, {
      error: () => this.props.loadingFailure(),
      success: (json) => {
        data.successfulLoading(json);
        this.counter++;
        if (this.counter < this.requestData.length) {
          this.loadFormData();
        } else {
          this.props.loadingSuccessful();
        }
      },
    });
  };

  componentDidMount() {
    this.loadFormData();

    $(window).keydown(function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        return console.log("Die Taste Enter ist deaktiviert");
      }
    });
  }

  render() {
    if (this.props.loading) {
      return <LoadingScreen loads={this.state.request.name} />;
    } else if (this.props.successfulSave) {
      return <SuccessfulSave />;
    } else if (this.props.isChanging) {
      return <IsChangingScreen />;
    } else if (this.props.errors.member === "not_found") {
      return <NotValidMember />;
    }
    return <MembershipForm />;
  }
}

const ConnectedEditMembership = connect(
  (state) => {
    const { inAenderung } = state.editMembership.data;
    return {
      loading: state.editMembership.loading,
      isChanging: inAenderung,
      errors: state.editMembership.errors,
      successfulSave: state.editMembership.successfulSave,
    };
  },
  {
    loadingMemberSuccessful: dataReceived,
    loadingSuccessful: formularDataLoadingSuccessful,
    loadingFailure: notValidMember,
    loadingRanksSuccessful: loadingRanksSuccessful,
    loadingEmploymentPlacesSuccessful: loadingEmploymentPlacesSuccessful,
    loadingMilitaryUnitsSuccessful: loadingMilitaryUnitsSuccessful,
  }
)(EditMembership);

class Root extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <div>
          <ConnectedEditMembership />
        </div>
      </Provider>
    );
  }
}

export default {
  "dbwv/Aenderungsmeldung": function standaloneMembership(container) {
    return ReactDOM.render(<Root />, container);
  },
};
