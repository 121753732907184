import React from "react";
import map from "lodash/map";
import UserMessageBox from "./UserMessageBox";
import hashing from "./hashing";
import ChangeLabel from "./ChangeLabel";

class Option extends React.Component {
  render() {
    return <option value={this.props.optionValue}>{this.props.label}</option>;
  }
}

class Select extends React.Component {
  render() {
    return (
      <select
        id={this.props.hashedId}
        name={this.props.name}
        value={this.props.value}
        onFocus={this.props.onFocus}
        onChange={this.props.onChange}
        key={this.props.name}
        disabled={this.props.disabled}
      >
        <Option label="Bitte auswählen" key="empty" optionValue="" />
        {map(this.props.options, (option) => (
          <Option
            label={option.label}
            key={this.props.name + "_" + option.value}
            optionValue={option.value}
          />
        ))}
      </select>
    );
  }
}

const SelectField = ({
  input,
  label,
  meta,
  options,
  initialValue,
  modifyAble,
  requiresUnlock,
}) => {
  const hashedId = hashing(input.name);
  return (
    <div>
      <div className="label-container flex justify-between pb-2">
        <label htmlFor={hashedId} className="custom-label">
          {label}
        </label>
        {requiresUnlock ? (
          <ChangeLabel
            inputName={input.name}
            disabled={
              input.value === initialValue && initialValue !== "" && !modifyAble
            }
          />
        ) : (
          ""
        )}
      </div>
      <div>
        <Select
          hashedId={hashedId}
          name={input.name}
          value={input.value}
          defaultValue={input.value}
          onFocus={input.onFocus}
          onChange={input.onChange}
          options={options}
          disabled={input.value === initialValue && !modifyAble}
        />
        <UserMessageBox meta={meta} />
      </div>
    </div>
  );
};

export default SelectField;
